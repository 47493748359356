import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import React from "react";
import { FaTwitter, FaLinkedinIn, FaFacebookF } from "react-icons/fa";
import { globalClasses } from "../MainLayout/styles";
import { aboutItems, productsItems, servicesItems } from "./constants";
import FooterNavList from "./FooterNavList";
import { classes, Root } from "./styles";
import { QUALIOPI_HREF } from "../../../util/constants";

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Root className={globalClasses.innoGreyGradient}>
      <Container>
        <Grid container className={classes.row}>
          <Grid
            item
            container
            sm={12}
            md={5}
            direction="column"
            alignItems="flex-start"
            justifyContent={isMobile ? "center" : "flex-start"}
            className={clsx({ [classes.rowItem]: isMobile })}
          >
            <img
              className={classes.img}
              src="/images/logo-innovance-solutions-white-footer.svg"
              alt="Innovance Solutions"
              width="200"
              height="55"
            />
            {/* <a href={QUALIOPI_HREF} target="_blank">
              <img
                className={classes.img}
                src="/images/qualiopi.png"
                alt="Qualiopi"
                width="150"
                height="55"
              />
            </a> */}
          </Grid>
          <Grid
            item
            container
            sm={12}
            md={7}
            alignItems="flex-start"
            justifyContent="flex-end"
            className={clsx({ [classes.rowItem]: isMobile })}
          >
            <Grid
              item
              container
              xs={12}
              sm={4}
              alignItems="center"
              justifyContent={isXs ? "center" : "flex-start"}
            >
              <FooterNavList headerText="Nos produits" items={productsItems} />
            </Grid>
            {/* <Grid
              item
              container
              xs={12}
              sm={4}
              alignItems="center"
              justifyContent={isXs ? "center" : "flex-start"}
            >
              <FooterNavList headerText="Nos services" items={servicesItems} />
            </Grid> */}
            <Grid
              item
              container
              xs={12}
              sm={4}
              alignItems="center"
              justifyContent={isMobile ? "center" : "flex-start"}
            >
              <FooterNavList headerText="A propos" items={aboutItems} />
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container className={classes.row}>
          <Grid
            item
            container
            xs={12}
            sm={6}
            alignItems="center"
            justifyContent={isXs ? "center" : "flex-start"}
            className={clsx({ [classes.rowItem]: isXs })}
          >
            <Typography variant="body2" color="inherit">
              © {new Date().getFullYear()}, Innovance Solutions
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={6}
            alignItems="center"
            justifyContent={isXs ? "center" : "flex-end"}
            className={clsx({ [classes.rowItem]: isXs })}
          >
            <Typography variant="body1" color="inherit">
              Suivez notre actualité :{" "}
            </Typography>
            <IconButton
              href="https://twitter.com/innovance_s"
              target="_blank"
              className={classes.social}
            >
              <FaTwitter fontSize="medium" />
            </IconButton>
            <IconButton
              href="https://www.linkedin.com/company/innovance-solutions/"
              target="_blank"
              className={classes.social}
            >
              <FaLinkedinIn fontSize="medium" />
            </IconButton>
            <IconButton
              href="https://www.facebook.com/innovancesolutions"
              target="_blank"
              className={classes.social}
            >
              <FaFacebookF fontSize="medium" />
            </IconButton>
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
};

export default Footer;
